<template>
  <v-row>
    <v-col
      cols="12"
      md="6">
      <v-card class="pa-4 card-content">
        <div>
          <span class="font-weight-bold font-size-md">Enter your web URL</span>
          <v-text-field
            v-model.trim="url"
            hide-details
            placeholder="URL the website"
            dense
            outlined />
        </div>
        <div
          v-if="qr"
          class="text-center mt-8">
          <span class="font-weight-bold font-size-md">Live preview</span>
          <img
            :src="qr"
            alt=""
            class="ma-auto qr-wrapper"
            srcset="">
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6">
      <v-card class="pa-4 card-content">
        <v-select
          v-model="options.errorCorrectionLevel"
          hide-details
          outlined
          label="Correction Level"
          :items="errorCorrectionLevelOptions"
          item-text="name"
          item-value="value">
        </v-select>
        <v-select
          v-model="options.type"
          hide-details
          outlined
          class="mt-5"
          label="Type"
          :items="typeOptions"
          item-text="name"
          item-value="value">
        </v-select>

        <v-slider
          v-model.number="options.quality"
          label="Quality"
          step="0.1"
          min="0"
          max="1"
          class="mt-5"
          ticks="always"
          thumb-label
          tick-size="4"
        ></v-slider>

        <v-slider
          v-model.number="options.margin"
          label="Margin"
          min="0"
          max="24"
          class="mt-5"
          thumb-label
        ></v-slider>

        <v-slider
          v-model.number="options.scale"
          label="Scale"
          min="0"
          max="10"
          class="mt-5"
          thumb-label
        ></v-slider>

        <v-text-field
          v-model.number="options.width"
          hide-details
          type="number"
          label="Width (px)"
          placeholder="Width (px)"
          outlined />

        <v-row>
          <v-col>
            <div class="my-5 ">
              QR Color
            </div>
            <v-color-picker
              v-model="options.color.dark"
              dot-size="28"
              mode="hexa"
            ></v-color-picker>
          </v-col>
          <v-col>
            <div class="my-5">
              Background Color
            </div>
            <v-color-picker
              v-model="options.color.light"
              dot-size="28"
              mode="hexa"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import QRCode from 'qrcode'

export default {
  data () {
    return {
      qr: null,
      url: 'www.gentlewomanonline.com',
      errorCorrectionLevelOptions: [
        {
          name: 'L (Low)',
          value: 'L'
        },
        {
          name: 'M (Medium)',
          value: 'M'
        },
        {
          name: 'Q (Quartile)',
          value: 'Q'
        },
        {
          name: 'H (High)',
          value: 'H'
        }
      ],
      typeOptions: [
        {
          name: 'JPEG',
          value: 'image/jpeg'
        },
        {
          name: 'PNG',
          value: 'image/png'
        }
      ],
      options: {
        errorCorrectionLevel: 'M',
        type: 'image/png',
        quality: 0.9,
        margin: 4,
        scale: 4,
        width: 300,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      }
    }
  },
  watch: {
    url (value) {
      if (value === '') {
        this.qr = null
      } else {
        this.generateQRCode()
      }
    },
    options: {
      handler () {
        this.generateQRCode()
      },
      deep: true
    }
  },
  mounted () {
    this.generateQRCode()
  },
  methods: {
    async generateQRCode () {
      this.qr = null
      this.qr = await QRCode.toDataURL(this.url, this.options)
    }
  }
}
</script>
<style lang="css">
.qr-wrapper {
  border: 1px solid #e2e2e2;
  box-shadow: #e2e2e2;
  border-radius: 6px;
}
.card-content {
  height: calc(100vh - 100px);
  overflow: auto;
}
</style>
